import it from "./it";
import en from "./en";
import fr from "./fr";
import de from "./de";
import es from "./es";

export default {
  en,
  it,
  fr,
  de,
  es,
};
